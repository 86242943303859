/* eslint-disable no-useless-escape */
<template>
  <div id="newsletter" class="bg-black py-20 px-8 sm:pb-36">
    <b-loading :is-full-page="fullPage" :active.sync="sendingMessage"></b-loading>
    <div class=" sm:w-2/5 sm:m-auto sm:relative sm:left-10">
      <div class = "" v-if="!sent">
        <p class="text-white font-roboto font-semibold text-lg sm:text-xl mb-4">
          {{$t('contact.newsletter.card.titlu-newsletter')}}  <br class="sm:hidden">
          {{$t('contact.newsletter.card.titlu-newsletter2')}}
        </p>
        <p class="text-white font-roboto font-normal text-base sm:text-xl mb-10 sm:mb-4 sm:-mt-2">
          {{$t('contact.newsletter.card.subtitlu-newsletter')}}
        </p>
        <input class="w-full sm:w-10/12 py-2 pl-5 rounded-md"
               :class="{'error-outline': errorOutline}"
               v-model="email_addr"
               type="text"
               name="input-n"
               placeholder="ion.popescu@gmail.com"
        >
        <br>
        <div class="flex justify-start items-start sm:items-center mt-3">
          <input type="checkbox" v-model="checked" class="sm:-mr-3"/>
          <p class="text-white font-roboto opacity-50 ml-5">
            {{$t('contact.newsletter.card.captcha-newsletter')}}
          </p>
        </div>
         <a @click="enrollInNewsletter()">
          <button class="text-white font-roboto bg-dark_red w-full sm:w-72 py-2 rounded-md mt-5">
            {{$t('contact.newsletter.card.trimite')}}
          </button>
        </a>
        <h1 class = "mt-2 font-roboto text-white" v-if="errorCheckbox"> {{$t('contact.newsletter.checkboxError')}} </h1>
        <h1 class = "mt-2 font-roboto text-white" v-if="errorEmail"> {{$t('contact.newsletter.invalidEmail')}} </h1>
        <h1 class = "mt-2 font-roboto text-white" v-if="networkError"> {{$t('contact.newsletter.networkError')}} </h1>
      </div>
      <div v-else>
        <h1 v-if="sent" class = "text-white font-roboto text-xl"> {{$t('contact.newsletter.sentMessage')}}</h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      checked: false,
      sent: false,
      errorCheckbox: false,
      errorEmail: false,
      networkError: false,
      errorOutline: false,
      email_addr: '',
      api: 'https://us-central1-eestec-backend.cloudfunctions.net/app/api/eestec/newsletter',
      fullPage: true,
      sendingMessage: false,
    };
  },
  methods: {
    enrollInNewsletter() {
      this.resetVariables();
      if (!this.checked) {
        this.errorCheckbox = true;
        this.errorOutline = true;
      } else if (!this.validateEmail(this.email_addr)) {
        this.errorEmail = true;
        this.errorOutline = true;
      } else {
        const payload = {
          email_addr: this.email_addr,
        };
        this.sendingMessage = true;
        this.$http.post(this.api, payload).then(() => {
          this.sent = true;
          this.sendingMessage = false;
        },
        () => {
          this.networkError = true;
          this.sendingMessage = false;
        });
      }
    },
    resetVariables() {
      this.sent = false;
      this.errorCheckbox = false;
      this.errorEmail = false;
      this.networkError = false;
      this.errorOutline = false;
    },
    validateEmail(email) {
      // eslint-disable-next-line max-len, no-useless-escape
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
  },
};
</script>

<style lang="scss" scoped>
.error-outline {
  border: 3px solid $dark_red;
}
</style>
